// red dot
export const stylePassengerRoute = {
  "layout": {
    "line-join": "round",
    "line-cap": "round"
  },
  "paint": {
    "line-color": "#ec3030",
    "line-width": 2,
    "line-opacity": 0.8,
    "line-dasharray": [1.5, 2.5]
  }
}

// blue line
export const styleBusRoute = {
  "layout": {
    "line-join": "round",
    "line-cap": "round"
  },
  "paint": {
    "line-color": "#004ca9",
    "line-width": 3,
    "line-opacity": 0.8
  }

}


export const styleBusRouteCustomColor = (color) => {
  return {
    "layout": {
      "line-join": "round",
      "line-cap": "round"
    },
    "paint": {
      "line-color": color,
      "line-width": 3,
      "line-opacity": 0.8
    }
  }

}


