import { STORAGE_EMAIL, STORAGE_CUST_ID, STORAGE_FIRST_NAME, STORAGE_LAST_NAME, STORAGE_MAPBOX_TOKEN } from './../constant';
import { Injectable } from '@angular/core';
import * as moment from 'moment'
import { Storage } from '@ionic/storage';
import _ from "lodash";
@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
    private storage: Storage
  ) { }

  parseLngLatToArray(lnglat: string) {
    return [parseFloat(lnglat.split(',')[0]), parseFloat(lnglat.split(',')[1])]
  }


  swapCoords(coords: any) {
    coords.map((pos, idx) => {
      coords[idx] = [pos[1], pos[0]]
    })
    return coords
  }

  toTimeTypeB(time) {

    const mins = Math.floor(time / 60)
    const sec = Math.floor(time % 60)
    let minDis = ''
    let secDis = ''

    minDis += (mins < 10 ? "0" + mins : mins)
    secDis += sec < 10 ? "0" + sec : sec

    return + minDis + ':' + secDis + ' Mins';

  }

  toTimeTypeA(inputSeconds) {

    const Days = Math.floor(inputSeconds / (60 * 60 * 24));
    const Hour = Math.floor((inputSeconds % (60 * 60 * 24)) / (60 * 60));
    const Minutes = Math.floor(((inputSeconds % (60 * 60 * 24)) % (60 * 60)) / 60);
    const Seconds = Math.floor(((inputSeconds % (60 * 60 * 24)) % (60 * 60)) % 60);
    let ddhhmmss = '';

    // console.log('toDDHHMMSS d', Days, 'h', Hour, 'm', Minutes, 's', Seconds)

    if (Days > 0) {
      ddhhmmss += Days
    }
    if (Hour > 0) {
      ddhhmmss += Hour + (Minutes > 0 ? ':' : '');
    }

    if (Minutes > 0) {
      ddhhmmss += ((Minutes < 10 && Hour > 0) ? '0' + Minutes : Minutes) + (Seconds > 0 ? ':' : '');
    }

    if (Seconds > 0) {
      ddhhmmss += ((Seconds < 10 && Minutes > 0) ? '0' + Seconds : Seconds);
    }

    if (Days > 0) {
      ddhhmmss += ' Day ';
    }

    else if (Hour > 0) {
      ddhhmmss += ' Hour ';
    }

    else if (Minutes > 0) {
      ddhhmmss += ' Mins ';
    }

    else if (Seconds > 0) {
      ddhhmmss += ' Sec ';
    }

    return ddhhmmss;

  }

  toTimeTypeC(inputSeconds) {

    const Days = Math.floor(inputSeconds / (60 * 60 * 24));
    const Hour = Math.floor((inputSeconds % (60 * 60 * 24)) / (60 * 60));
    const Minutes = Math.floor(((inputSeconds % (60 * 60 * 24)) % (60 * 60)) / 60);
    const Seconds = Math.floor(((inputSeconds % (60 * 60 * 24)) % (60 * 60)) % 60);
    let ddhhmmss = '';

    // console.log('toDDHHMMSS d', Days, 'h', Hour, 'm', Minutes, 's', Seconds)

    if (Days > 0) {
      ddhhmmss += Days + ' d ';
    }

    if (Hour > 0) {
      ddhhmmss += Hour + ' h ';
    }

    if (Minutes > 0) {
      ddhhmmss += Minutes + ' m ';
    }

    if (Seconds > 0 && Minutes <= 0) {
      ddhhmmss += '1 m ';
    }

    return ddhhmmss;

  }


  GetCenterFromDegrees(data) {
    if (!(data.length > 0)) {
      return false;
    }

    var num_coords = data.length;

    var X = 0.0;
    var Y = 0.0;
    var Z = 0.0;

    for (var i = 0; i < data.length; i++) {
      var lat = data[i][0] * Math.PI / 180;
      var lon = data[i][1] * Math.PI / 180;

      var a = Math.cos(lat) * Math.cos(lon);
      var b = Math.cos(lat) * Math.sin(lon);
      var c = Math.sin(lat);

      X += a;
      Y += b;
      Z += c;
    }

    X /= num_coords;
    Y /= num_coords;
    Z /= num_coords;

    var lon = Math.atan2(Y, X);
    var hyp = Math.sqrt(X * X + Y * Y);
    var lat = Math.atan2(Z, hyp);

    var newX = (lat * 180 / Math.PI);
    var newY = (lon * 180 / Math.PI);

    return new Array(newX, newY);
  }

  compareArray(arr1, arr2) {

    if (!arr1 || !arr2) return

    let result;

    arr1.forEach((e1, i) => arr2.forEach(e2 => {

      if (e1.length > 1 && e2.length) {
        result = this.compareArray(e1, e2);
      } else if (e1 !== e2) {
        result = false
      } else {
        result = true
      }
    })
    )

    return result

  }

  getDistanceFromLatLonInMeter(lat1, lon1, lat2, lon2) {
    let R = 6371; // Radius of the earth in km
    let dLat = this.deg2rad(lat2 - lat1);  // deg2rad below
    let dLon = this.deg2rad(lon2 - lon1);
    let a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);

    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = (R * c) * 1000; // Distance in km
    return d;
  }

  deg2rad(deg) {
    return deg * (Math.PI / 180)
  }

  getEmail(){
    return new Promise((resolve, reject) => {
      this.storage.get(STORAGE_EMAIL).then((value) => {
        console.log('getEmail success:', value);
        resolve(value || "")
      }).catch((error) => {
        console.log('getEmail error:', error);
        resolve("")
      })
    });

  }

  getCustId(){
    return new Promise((resolve, reject) => {
      this.storage.get(STORAGE_CUST_ID).then((value) => {
        console.log('cust_id success:', value);
        resolve(value || 0)
      }).catch((error) => {
        console.log('cust_id error:', error);
        resolve(0)
      })
    });
  }

  getFirstName(){
    return new Promise((resolve, reject) => {
      this.storage.get(STORAGE_FIRST_NAME).then((value) => {
        console.log('firstName success:', value);
        resolve(value || "")
      }).catch((error) => {
        console.log('firstName error:', error);
        resolve("")
      })
    });
  }

  getLastName(){
    return new Promise((resolve, reject) => {
      this.storage.get(STORAGE_LAST_NAME).then((value) => {
        console.log('lastName success:', value);
        resolve(value || "")
      }).catch((error) => {
        console.log('lastName error:', error);
        resolve("")
      })
    });
  }

  getMapBoxToken(): Promise<string>{
    return new Promise((resolve, reject) => {
      this.storage.get(STORAGE_MAPBOX_TOKEN).then((value) => {
        console.log('getMapBoxToken success:', value);
        resolve(value || "")
      }).catch((error) => {
        console.log('getMapBoxToken error:', error);
        resolve(null)
      })
    });
  }

    /**
     * Calculates the distance between two lat, long coordinate pairs
     */
    getPathLength(lat1, lng1, lat2, lng2) {
      let lat1rads, lat2rads, deltaLat, deltaLng,
          a, c, dist_metre, R;

      // Avoid to return NAN, if finding distance between same lat long.
      if (lat1 == lat2 && lng1 == lng2) {
          return 0;
      }

      //Earth Radius (in metre)
      R = 6371000

      lat1rads = this.degreesToRadians(lat1)
      lat2rads = this.degreesToRadians(lat2)
      deltaLat = this.degreesToRadians((lat2 - lat1))
      deltaLng = this.degreesToRadians((lng2 - lng1))

      a = Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
          Math.cos(lat1rads) * Math.cos(lat2rads) * Math.sin(deltaLng / 2) * Math.sin(deltaLng / 2)
      c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

      dist_metre = R * c;

      if (isNaN(dist_metre)) {
          return 0;
      }

      return dist_metre
  }

  degreesToRadians(degree) {
      return degree * Math.PI / 180;
  }

  radiansToDegrees(radians) {
      return radians * 180 / Math.PI;
  }

  /**
   * returns the lat and long of destination point
   * given the start lat, long, aziuth, and distance.
   */
  _getDestinationLatLong(lat, lng, azimuth, distance_metre) {
      let lat2, lng2, R, brng, d_km, lat1, lng1;

      R = 6378.1 //Radius of the Earth in km

      //Bearing is degrees converted to radians.
      brng = this.degreesToRadians(azimuth);
      d_km = distance_metre / 1000;
      lat1 = this.degreesToRadians(lat)
      lng1 = this.degreesToRadians(lng)

      lat2 = Math.asin(Math.sin(lat1) * Math.cos(d_km / R) +
          Math.cos(lat1) * Math.sin(d_km / R) * Math.cos(brng))
      lng2 = lng1 +
          Math.atan2(
              Math.sin(brng) * Math.sin(d_km / R) * Math.cos(lat1),
              Math.cos(d_km / R) - Math.sin(lat1) * Math.sin(lat2));

      //convert back to degrees
      lat2 = this.radiansToDegrees(lat2)
      lng2 = this.radiansToDegrees(lng2)

      return [parseFloat(lat2.toFixed(6)), parseFloat(lng2.toFixed(6))]
  }

  /**
   * calculates the azimuth in degrees from start point to end point
   */
  calculateBearing(lat1, lng1, lat2, lng2) {
      let startLat, startLong, endLat, endLong, dLong, dPhi, bearing;

      startLat = this.degreesToRadians(lat1)
      startLong = this.degreesToRadians(lng1)
      endLat = this.degreesToRadians(lat2)
      endLong = this.degreesToRadians(lng2)

      dLong = endLong - startLong
      dPhi = Math.log(Math.tan(endLat / 2.0 + Math.PI / 4.0) / Math.tan(startLat / 2.0 + Math.PI / 4.0));

      if (Math.abs(dLong) > Math.PI) {
          if (dLong > 0) {
              dLong = -(2.0 * Math.PI - dLong)
          } else {
              dLong = (2.0 * Math.PI + dLong)
          }
      }

      bearing = (this.radiansToDegrees(Math.atan2(dLong, dPhi)) + 360.0) % 360.0;

      return bearing;
  }

  /**
   * Invoke to returns every coordinate pair in-between two coordinate pairs
   * given the desired interval
   */
  _buildCoordinates(interval, azimuth, lat1, lng1, lat2, lng2) {
      let d, dist, counter, coords, range_list, _coord;

      d = this.getPathLength(lat1, lng1, lat2, lng2);

      dist = +(d / interval)

      coords = [];
      coords.push([lat1, lng1]);
      //:::::::::::::::::::::::::::::::::::::
      //::::: Lodash/UnderScore lib     :::::
      //:::::::::::::::::::::::::::::::::::::
      range_list = _.range(0, dist);

      counter = parseFloat(interval)

      for (let key in range_list) {
          _coord = this._getDestinationLatLong(lat1, lng1, azimuth, counter)
          counter = counter + parseFloat(interval)
          coords.push(_coord);
      }

      coords.push([lat2, lng2])

      return coords;

  }

  /**
   * Invoke to get coordinates between two location
   */
  getCoordinates(lat1, lng1, lat2, lng2, interval_meters) {
      let azimuth, coords;

      // point interval in meters
      if (!interval_meters) {
          interval_meters = 20.0
      }

      azimuth = this.calculateBearing(lat1, lng1, lat2, lng2)
      coords = this._buildCoordinates(interval_meters, azimuth, lat1, lng1, lat2, lng2)

      return coords;
  }

}
