import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-rate',
  templateUrl: './rate.component.html',
  styleUrls: ['./rate.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class RateComponent implements OnInit {

  starList = []
  rate = 0
  commentTxt = ""

  constructor() {  }

  ngOnInit() {
    console.log('init RateComponent');
    for (let i = 0; i < 5; i++) {
      this.starList.push({
        id: (i + 1),
        active: 0
      })
    }

    // default is 5 star
    this.checkActive(this.starList[4])
  }

  checkActive(item){
    for (let i in this.starList){
      if (this.starList[i].id <= item.id) {
        this.starList[i].active = 1
        this.rate = item.id
      } else {
        this.starList[i].active = 0
      }
    }
  }

  sendFeedBack(){
    console.log('== sendFeedBack ==');
    console.log('rate', this.rate);
    console.log('this.comment', this.commentTxt);
    let data = {
      rate: this.rate,
      comment: this.commentTxt
    }
    this['close'](data)
  }

}
