import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: './home/home.module#HomePageModule'
  },
  { path: 'booking-retrieve-valet-service', loadChildren: './booking-retrieve-valet-service/booking-retrieve-valet-service.module#BookingRetrieveValetServiceModule' },
  { path: 'booking-menu', loadChildren: './booking-menu/booking-menu.module#BookingMenuPageModule' },
  { path: 'booking-detail', loadChildren: './booking-detail/booking-detail.module#BookingDetailPageModule' },
  { path: 'booking-success', loadChildren: './booking-success/booking-success.module#BookingSuccessPageModule' },
  { path: 'booking-new-quote', loadChildren: './booking-new-quote/booking-new-quote.module#BookingNewQuotePageModule' },
  { path: 'park-and-ride-retrieve-form', loadChildren: './park-and-ride-retrieve-form/park-and-ride-retrieve-form.module#ParkAndRideRetrieveFormPageModule' },
  { path: 'booking-detail-account-single-journey', loadChildren: './booking-detail-account-single-journey/booking-detail-account-single-journey.module#BookingDetailAccountSingleJourneyPageModule' },
  { path: 'booking-detail-account-return-journey', loadChildren: './booking-detail-account-return-journey/booking-detail-account-return-journey.module#BookingDetailAccountReturnJourneyPageModule' },
  { path: 'car-park-shuttle', loadChildren: './car-park-shuttle/car-park-shuttle.module#CarParkShuttlePageModule' },
  { path: 'village-shuttle', loadChildren: './village-shuttle/village-shuttle.module#VillageShuttlePageModule' },
  { path: 'track-your-driver', loadChildren: './track-your-driver/track-your-driver.module#TrackYourDriverPageModule' },
  { path: 'contact-us', loadChildren: './contact-us/contact-us.module#ContactUsPageModule' },
  { path: 'share-ride-new-quote', loadChildren: './share-ride-new-quote/share-ride-new-quote.module#ShareRideNewQuotePageModule' },
  { path: 'share-ride-menu', loadChildren: './share-ride-menu/share-ride-menu.module#ShareRideMenuPageModule' },
  { path: 'share-ride-booking-success', loadChildren: './share-ride-booking-success/share-ride-booking-success.module#ShareRideBookingSuccessPageModule' },
  { path: 'square-payment', loadChildren: './square-payment/square-payment.module#SquarePaymentPageModule' },
  { path: 'payment-success', loadChildren: './payment-success/payment-success.module#PaymentSuccessPageModule' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
