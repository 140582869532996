import { Injectable } from '@angular/core';
import { FCM } from '@ionic-native/fcm/ngx';
import { Observable } from 'rxjs';
import { RequestService } from './request.service';
import { Storage } from '@ionic/storage';
import { STORAGE_FCM_CHANNEL } from '../constant';

@Injectable({
  providedIn: 'root'
})

export class FcmService {

  private _token

  constructor(
    private fcm: FCM,
    private requestSerevice: RequestService,
    private storage: Storage) {  }

  get Token (){
    return this._token
  }

  set Token (token) {
    this._token = token
    this.defineNotificationChannel()
    this.requestSerevice.sendFcmTokenInfo(token)
      .then(data => {console.log('sendFcmTokenInfo:', data)})
      .catch(err => {console.log('sendFcmTokenInfo err:', err)})
  }

  public async initFcm() {
    console.log('initFCM');
    this.fcm.getToken()
    .then((token) => {
      if (token == null) {
        this.refreshToken()
      } else {
        this.Token = token
      }
      console.log('FcmService getToken:', token);
    }).catch((error) => {
      console.log('FcmService getToken error:', error);
    })
  }

  public async subscribeToTopic(channel: string){
    let tokenInfo = await this.requestSerevice.sendFcmTokenInfo(this.Token)
    if (tokenInfo['data']) {
      let deviceChannel = `${channel}.${tokenInfo['data']['platform']}`
      await this.fcm.subscribeToTopic(deviceChannel)
      .then(data => {console.log('subscribeToTopic:', data)})
      .catch(err => {console.log('subscribeToTopic err:', err)})
    }
  }

  public async unsubscribeFromTopic(channel: string){
    let tokenInfo = await this.requestSerevice.sendFcmTokenInfo(this.Token)
    if (tokenInfo['data']) {
      let deviceChannel = `${channel}.${tokenInfo['data']['platform']}`
      await this.fcm.unsubscribeFromTopic(deviceChannel)
        .then(data => {console.log('unsubscribeFromTopic:', data)})
        .catch(err => {console.log('unsubscribeFromTopic err:', err)})
    }
  }

  public refreshToken(){
    this.fcm.onTokenRefresh()
    .subscribe(token => {
      console.log('onTokenRefresh token:', token);
      this.Token = token
    })
  }

  private async defineNotificationChannel(){
    let fcm_channel = await this.storage.get(STORAGE_FCM_CHANNEL)
    if (fcm_channel != null && fcm_channel.trim('') != '') {
      await this.subscribeToTopic(fcm_channel)
      .then(data => {console.log('defineNotificationChannel:', data)})
      .catch(err => {console.log('defineNotificationChannel err:', err)})
    }
  }

  /**
   * onNotificationRecieve
   *
   * example
   *
   * this.fcm.onNotification().subscribe(data => {
   *   if (data.wasTapped){
   *     console.log("Received in background:", data);
   *   } else {
   *     console.log("Received in foreground:", data);
   *   }
   * })
   */

  public onNotificationRecieve(){
    console.log('onNotificationRecieve subscribe');
    return this.fcm.onNotification()
  }

}
