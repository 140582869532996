export const BUILD_NUMBER = "L02G20A"
export const VERSION_NUMBER = "2020007020010908"

//export const API = 'http://localhost:8090/api/v1/'

//export const API = 'https://bd6c0aef.ngrok.io/api/v1/'

//uat 35.244.80.135
// export const API = 'https://mtwebservice.ecoachmanager.com/passenger/api/v1/'

// live 13.236.74.27 8090
export const API = 'https://mtbullerwebservice.ecoachmanager.com/passenger/api/v1/'

//GCP
// export const API = 'http://35.240.219.164:8090/api/v1/'
