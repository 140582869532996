import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FCM } from '@ionic-native/fcm/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { NgClockPickerLibModule } from 'ng-clock-picker-lib-voova';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BackgroundGeolocation } from '@ionic-native/background-geolocation/ngx';
import { LocationService } from './services/location.service';
import { FcmService } from './services/fcm.service';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NgbDate, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IonicStorageModule } from '@ionic/storage';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { RateService } from './services/rate.service';
import { RateComponent } from './components/rate/rate.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [AppComponent, RateComponent],
  entryComponents: [RateComponent],
  imports: [
    BrowserModule,
    NgbModule,
    NgClockPickerLibModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    FormsModule
  ],
  providers: [
    InAppBrowser,
    Geolocation,
    FCM,
    FcmService,
    LocationService,
    RateService,
    StatusBar,
    SplashScreen,
    BackgroundGeolocation,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
