import { Component, ViewContainerRef } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { MapboxBuilderService } from './services/mapbox-builder.service'
import { LocationService } from './services/location.service'
import { FcmService } from './services/fcm.service';
import { RateService } from './services/rate.service';
import { Storage } from '@ionic/storage';
import { STORAGE_MAPBOX_TOKEN, SQUARE_CONFIG } from '../app/constant';
import { RequestService } from './services/request.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private mapBuilder: MapboxBuilderService,
    private fcmService: FcmService,
    private location: LocationService,
    private vcr: ViewContainerRef,
    private rateService: RateService,
    private storage: Storage,
    private request: RequestService,
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      console.log('Platform ready app')
      this.statusBar.styleDefault()
      this.statusBar.overlaysWebView(false)
      this.splashScreen.hide()
      this.getMapBoxToken()
      this.fcmService.initFcm()
      this.rateService.registerViewContainerRef(this.vcr)
      this.getSquareConfig()
    });
  }

  async getMapBoxToken() {
    let mapboxToken = await this.request.getMapBoxToken()
    console.log('getMapBoxToken -- :', mapboxToken);
    if (mapboxToken && mapboxToken['token'] && mapboxToken['token'] != "") {
      let token = mapboxToken['token']
      console.log('SET STORAGE_MAPBOX_TOKEN', token);
      await this.storage.set(STORAGE_MAPBOX_TOKEN, token)
    } else {
      console.log('SET STORAGE_MAPBOX_TOKEN TO NULL');
      await this.storage.set(STORAGE_MAPBOX_TOKEN, null)
    }
    this.mapBuilder.initToken()
  }

  async getSquareConfig(){
    let squareConfig = await this.request.getSquareConfig()
    await this.storage.set(SQUARE_CONFIG, squareConfig)
  }
}
