import { Injectable } from '@angular/core'
import { Geolocation, GeolocationOptions } from '@ionic-native/geolocation/ngx';
import { Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';

@Injectable()
export class LocationService {
    subscriptionLocationService: Subscription
    myLocation: any
    geo_options: GeolocationOptions
    constructor(
        private geolocation: Geolocation,
        private platform: Platform
        ) {
        console.log('Hello LocationService Provider')
        this.geo_options = {
            enableHighAccuracy: true,
            maximumAge        : 2500,
            timeout           : 3000
        };
        this.platform.ready().then(() => {
            console.log('Hello LocationService platform ready');
            this.initLocation();
        })
    }

    initLocation() {
        console.log('initLocation')
        this.unsubscribeWatch();
        this.subscriptionLocationService = this.geolocation.watchPosition(this.geo_options)
                            .subscribe((position) => {
                                console.log('watchPosition position:', position);
                                if (position.coords !== undefined) {
                                    this.setPosition(position)
                                }
                            },
                            (error) => {
                                this.showError(error)
                            })
    }

    unsubscribeWatch(){
        if (this.subscriptionLocationService !== undefined) {
            this.subscriptionLocationService.unsubscribe()
            console.log('unsubscribeWatch location service !!');
        }
    }

    setPosition(position) {
        console.log('setPosition', position)
        this.myLocation = position
        // position.coords.latitude
        // position.coords.longitude
    }

    getLocation() {
        return this.myLocation
    }

    showError(error) {
        console.log('showError', error);
        switch (error.code) {
        case error.PERMISSION_DENIED:
            console.log("User denied the request for Geolocation.")
            break;
        case error.POSITION_UNAVAILABLE:
            console.log("Location information is unavailable.")
            break;
        case error.TIMEOUT:
            console.log("The request to get user location timed out.")
            break;
        case error.UNKNOWN_ERROR:
            console.log("An unknown error occurred.")
            break;
        }
  }
}
