import { Storage } from '@ionic/storage';
import { Injectable } from '@angular/core';
import axios from 'axios';
import { API, VERSION_NUMBER } from '../settting';
import { STORAGE_CUST_ID, STORAGE_EMAIL, STORAGE_FIRST_NAME, STORAGE_LAST_NAME } from '../constant';

@Injectable({
  providedIn: 'root'
})

export class RequestService {

  private api = API
  private instance: any

  constructor(private storage: Storage) {}

  _axios() {

    if (this.instance == null) {

      this.instance = axios.create({
        baseURL: this.api,
        timeout: 30000,
        headers: this.getHeader()
      });
    }

    this.instance.defaults.headers = this.getHeader()
    return this.instance
  }

  getHeader() {
    return ({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'x-version': VERSION_NUMBER
    })
  }

  private getRandomInt() {
    return `?v` + Math.random();
  }

  private makeNoCache(prefix) {
    //prefix is ? or &
    return `${prefix}v` + Math.random();
  }


  public getRoute = (passenger_id, exteral_customer_id) => new Promise(async (resolve, reject) => {
    this._axios().get(`quote/route/${passenger_id}/${exteral_customer_id}` + this.getRandomInt())
      .then(res => {
        resolve(res.data.data)
      }).catch((error) => {
        reject({
          error: true,
          message: error
        })
      });
  })

  public getRouteShuttle = (job_id) => new Promise(async (resolve, reject) => {
    this._axios().get(`shuttle/route/pattern/` + job_id + this.getRandomInt())
      .then(res => {
        resolve(res.data.data)
      }).catch((error) => {
        reject({
          error: true,
          message: error
        })
      });
  })

  public getRouteShuttleFull = (job_id) => new Promise(async (resolve, reject) => {
    this._axios().get(`shuttle/route/pattern_full/` + job_id + this.getRandomInt())
      .then(res => {
        resolve(res.data.data)
      }).catch((error) => {
        reject({
          error: true,
          message: error
        })
      });
  })

  public getVillageShuttleRoute = () => new Promise(async (resolve, reject) => {
    let params = `?service_name=village shuttle`
    params += `&v=` + this.getRandomInt()
    this._axios().get(`shuttle/pattern${params}`)
      .then(res => {
        resolve(res.data.data)
      }).catch((error) => {
        reject({
          error: true,
          message: error
        })
      });
  })

  public getCarParkShuttleRoute = () => new Promise(async (resolve, reject) => {
    let params = `?service_name=Car Park Shuttle`
    params += `&v=` + this.getRandomInt()
    this._axios().get(`shuttle/pattern${params}`)
      .then(res => {
        resolve(res.data.data)
      }).catch((error) => {
        reject({
          error: true,
          message: error
        })
      });
  })

  public getCarParkShuttleTracking = () => new Promise(async (resolve, reject) => {
    let params = `?service_name=Car Park Shuttle`
    params += `&v=` + this.getRandomInt()
    this._axios().get(`shuttle/tracking${params}`)
      .then(res => {
        resolve(res.data.data)
      }).catch((error) => {
        reject({
          error: true,
          message: error
        })
      });
  })

  public getVillageShuttleTracking = () => new Promise(async (resolve, reject) => {
    let params = `?service_name=Village Shuttle`
    params += `&v=` + this.getRandomInt()
    this._axios().get(`shuttle/tracking${params}`)
      .then(res => {
        resolve(res.data.data)
      }).catch((error) => {
        reject({
          error: true,
          message: error
        })
      });
  })

  public getTracking = (param) => new Promise(async (resolve, reject) => {
    this._axios().get(`shuttle/route/tracking/` + param + this.getRandomInt())
      .then(res => {
        resolve(res.data.data)
      }).catch((error) => {
        reject({
          error: true,
          message: error
        })
      });
  })

  public checkPassengerTracking = (custId, email, firstName, lastName) => {
    let params = `?custId=${custId}`
    params += `&email=${email}`
    params += `&firstName=${firstName}`
    params += `&lastName=${lastName}`
    return new Promise((resolve, reject) => {
      this._axios().get(`passenger/job/${params}${this.makeNoCache('&')}`)
      .then(res => {
        resolve(res.data.data)
      }).catch((error) => {
        reject({
          error: true,
          message: error
        })
      });
    });
  }

  public getDriverPickupByQuoteId = (qid) => new Promise(async (resolve, reject) => {
    this._axios().get(`driver/pickup/?qid=` + qid + this.makeNoCache('&'))
      .then(res => {
        resolve(res.data.data)
      }).catch((error) => {
        reject({
          error: true,
          message: error
        })
      });
  })

  public getTrackingByQuoteId = (qid) => new Promise(async (resolve, reject) => {
    this._axios().get(`driver/tracking/?qid=` + qid + this.makeNoCache('&'))
      .then(res => {
        resolve(res.data.data)
      }).catch((error) => {
        reject({
          error: true,
          message: error
        })
      });
  })

  public getRetrieveBooking = (surname, registered_or_booking_id, token) => new Promise(async (resolve, reject) => {
    let params = ``
    params += `?surname=${surname}`
    params += `&reg_id=${registered_or_booking_id}`
    params += `&device_token=${token}`
    params += `&retrieve=1`

    this._axios().get(`booking/${params}`)
      .then(res => {
        resolve(res.data)
      }).catch((error) => {
        reject({
          error: true,
          message: error
        })
      });
  })

  public getRetrieveBookingByQuoteId = (qid, surname, registered_or_booking_id) => new Promise(async (resolve, reject) => {
    let params = ``
    params += `?surname=${surname}`
    params += `&reg_id=${registered_or_booking_id}`
    params += `&qid=${qid}`

    this._axios().get(`booking/${params}`)
      .then(res => {
        resolve(res.data)
      }).catch((error) => {
        reject({
          error: true,
          message: error
        })
      });
  })
  /**
   * createBooking
   */
  public updateBooking = (
    cust_id,
    qid,
    surname,
    reg_id,
    date_out1,
    date_out2,
    pickup_location,
    pickup_lat_lng,
    des_location,
    des_latlng,
    updateBookingType,
    deviceToken?) => new Promise(async (resolve, reject) => {

    let body = {
      cust_id: cust_id,
      qid: qid,
      surname: surname,
      reg_id: reg_id,
      date_out1: date_out1,
      date_out2: date_out2,
      pickup_location: pickup_location,
      pickup_lat_lng: pickup_lat_lng,
      des_location: des_location,
      des_latlng: des_latlng,
      updateBookingType: updateBookingType,
      deviceToken: deviceToken,
    }

    this._axios().put(`booking/`, body)
      .then(res => {
        resolve(res.data)
      }).catch((error) => {
        reject({
          error: true,
          message: error
        })
      });
  })

  /**
   * createBooking
   */
  
  public createBooking = (
    cust_name,
    firstname,
    surname,
    passengerName,
    email,
    cust_id,
    numPassenger,
    num_adult,
    num_children,
    tel,
    address,
    address_lat,
    address_lng,
    pickupDate,
    pickupTime,
    returnDate,
    returnTime,
    destination,
    destination_lat,
    destination_lng,
    txtNote,
    quote_type,
    deviceToken?,
    ) => new Promise(async (resolve, reject) => {
      let body = {
        cust_name: cust_name,
        firstname: firstname,
        surname: surname,
        passengerName: passengerName,
        email: email,
        cust_id: cust_id,
        numPassenger: numPassenger,
        num_adult: num_adult,
        num_children: num_children,
        phone: tel,
        address: address,
        address_lat: address_lat,
        address_lng: address_lng,
        pickupDate: pickupDate,
        pickupTime: pickupTime,
        returnDate: returnDate,
        returnTime: returnTime,
        destination: destination,
        destination_lat: destination_lat,
        destination_lng: destination_lng,
        txtNote: txtNote,
        quote_type: quote_type,
        deviceToken: deviceToken
      }
      this._axios().post(`booking/`, body)
      .then(res => {
        resolve(res.data)
      }).catch((error) => {
        reject({
          error: true,
          message: error
        })
      });
  })

  public getPlaceByLiveSearch = (keyword) => new Promise(async (resolve, reject) => {
    let params = ``
    params += `?keyword=${keyword}`

    this._axios().get(`livesearch/${params}`)
      .then(res => {
        resolve(res.data)
      }).catch((error) => {
        reject({
          error: true,
          message: error
        })
      });
  })

  public getCurrentLocation = (keyword) => new Promise(async (resolve, reject) => {
    let params = ``
    params += `?keyword=${keyword}`

    this._axios().get(`livesearch/current/${params}`)
      .then(res => {
        resolve(res.data)
      }).catch((error) => {
        reject({
          error: true,
          message: error
        })
      });
  })

  public getConfig = () => new Promise(async (resolve, reject) => {
    this._axios().get(`booking/config`)
      .then(res => {
        resolve(res.data)
      }).catch((error) => {
        reject({
          error: true,
          message: error
        })
      });
  })

  public getFeatureToggle = (feature) => new Promise(async (resolve, reject) => {
    this._axios().get(`featuretoggle/?feature=${feature}`)
      .then(res => {
        resolve(res.data)
      }).catch((error) => {
        reject({
          error: true,
          message: error
        })
      });
  })

  public collectLogs = (logs) => new Promise(async (resolve, reject) => {
    this._axios().post(`app/logs`, { data: logs })
      .then(res => {
        resolve(res.data)
      }).catch((error) => {
        reject({
          error: true,
          message: error
        })
      });
  })

  public getAppVersion = (versionNumber) => {
    return new Promise(async (resolve, reject) => {
      this._axios().get(`app/config/?version=${versionNumber}`)
        .then(res => {
          resolve(res.data)
        }).catch((error) => {
          reject({
            error: true,
            message: error
          })
        });
    })
  }

  public getSupportCallNumber = () => {
    return new Promise(async (resolve, reject) => {
      this._axios().get(`app/supportcall`)
        .then(res => {
          resolve(res.data)
        }).catch((error) => {
          console.log('getSupportCallNumber error:', error);
          resolve(null)
        });
    })
  }

  public getWarningTextBeforeBook = () => {
    return new Promise(async (resolve, reject) => {
      this._axios().get(`app/bookingwarning`)
        .then(res => {
          resolve(res.data)
        }).catch((error) => {
          console.log('getWarningTextBeforeBook error:', error);
          resolve(null)
        });
    })
  }

  public getWarningTextRetrieveBooking = () => {
    return new Promise(async (resolve, reject) => {
      this._axios().get(`app/retrievewarning`)
        .then(res => {
          resolve(res.data)
        }).catch((error) => {
          console.log('getWarningTextRetrieveBooking error:', error);
          resolve(null)
        });
    })
  }

  public getMapBoxToken = () => {
    return new Promise(async (resolve, reject) => {
      this._axios().get(`app/mapbox/token`)
        .then(res => {
          resolve(res.data)
        }).catch((error) => {
          console.log('getMapBoxToken error:', error);
          resolve(null)
        });
    })
  }

  public routeEnableStatus = () => {
    return new Promise(async (resolve, reject) => {
      this._axios().get(`app/mapbox/routestatus`)
        .then(res => {
          resolve(res.data)
        }).catch((error) => {
          console.log('routeEnableStatus error:', error);
          resolve(null)
        });
    })
  }

  public sendFcmTokenInfo = (token) => {
    return new Promise(async (resolve, reject) => {
      let custId = await this.storage.get(STORAGE_CUST_ID)
      this._axios().get(`messaging/info/${token}/${custId}`)
        .then(res => {
          resolve(res.data)
        }).catch((error) => {
          reject({
            error: true,
            message: error
          })
        });
    })
  }

  public rateDriver = (qid, driver_id, rate, comment, location) => new Promise(async (resolve, reject) => {

    let custId = await this.storage.get(STORAGE_CUST_ID) || ''
    let email = await this.storage.get(STORAGE_EMAIL) || ''
    let fname = await this.storage.get(STORAGE_FIRST_NAME) || ''
    let sname = await this.storage.get(STORAGE_LAST_NAME) || ''
    let name = fname + ' ' + sname
    this._axios().post(
      `driver/rate`,
      {
        qid: qid,
        driver_id: driver_id,
        rate: rate,
        comment: comment,
        custId: custId,
        email: email,
        name: name.trim(),
        feedback_from: 'Mt buller transport',
        location
      })
      .then(res => {
        resolve(res.data)
      }).catch((error) => {
        reject({
          error: true,
          message: error
        })
      });
  })

  public getTimeSlotsbyDate = (date) => new Promise(async (resolve, reject) => {
    this._axios().post(
      `timeslots/bydate`,
      {
        date: date
      })
      .then(res => {
        resolve(res.data)
      }).catch((error) => {
        reject({
          error: true,
          message: error
        })
      });
  })

  public getCarPark = () => new Promise(async (resolve, reject) => {
    this._axios().get(`locationcate/carpark`)
      .then(res => {
        resolve(res.data)
      }).catch((error) => {
        reject({
          error: true,
          message: error
        })
      });
  })

  public getVillageSquare = () => new Promise(async (resolve, reject) => {
    this._axios().get(`locationcate/villagesquare`)
      .then(res => {
        resolve(res.data)
      }).catch((error) => {
        reject({
          error: true,
          message: error
        })
      });
  })

  public checkSimulateBus = (
    quoteId,
    pickup,
    destination,
    cap,
    zone,
    pickupdatetime
    ) => new Promise(async (resolve, reject) => {
      let body = {
        quoteId: quoteId,
        pickup: pickup,
        destination: destination,
        cap: cap,
        zone:zone,
        pickupdatetime:pickupdatetime
      }
      this._axios().post(`simulatebus/checksimulatebus`, body)
      .then(res => {
        resolve(res.data)
      }).catch((error) => {
        reject({
          error: true,
          message: error
        })
      });
  })

  public createShareRideBooking = (
    isShareRide,
    isShareRideCarPark,
    isShareRideVillageSquare,
    quoteId,
    cust_name,
    firstname,
    surname,
    passengerName,
    email,
    cust_id,
    numPassenger,
    num_adult,
    num_child,
    tel,
    pickupDate,
    pickupTime,
    collection,
    collection_lat,
    collection_lng,
    destination,
    destination_lat,
    destination_lng,
    zone_id
    ) => new Promise(async (resolve, reject) => {
      let body = {
        isShareRide: isShareRide,
        isShareRideCarPark: isShareRideCarPark,
        isShareRideVillageSquare: isShareRideVillageSquare,
        quoteId: quoteId,
        cust_name: cust_name,
        firstname: firstname,
        surname: surname,
        passengerName: passengerName,
        email: email,
        cust_id: cust_id,
        numPassenger: numPassenger,
        num_adult: num_adult,
        num_children: num_child,
        phone: tel,
        pickupDate: pickupDate,
        pickupTime: pickupTime,
        address: collection,
        address_lat: collection_lat,
        address_lng: collection_lng,
        destination: destination,
        destination_lat: destination_lat,
        destination_lng: destination_lng,
        zone_id: zone_id
      }
      this._axios().post(`booking/`, body)
      .then(res => {
        resolve(res.data)
      }).catch((error) => {
        reject({
          error: true,
          message: error
        })
      });
  })

  public getSquareConfig = () => {
    return new Promise(async (resolve, reject) => {
      this._axios().get(`payment/square`)
        .then(res => {
          resolve(res.data)
        }).catch((error) => {
          console.log('getSquareConfig error:', error);
          resolve(null)
        });
    })
  }

  public squareCallBacks = (body) => {
    return new Promise(async (resolve, reject) => {
      this._axios().post(`payment/squarecallbacks`, body)
        .then(res => {
          resolve(res.data)
        }).catch((error) => {
          console.log('squareCallBacks error:', error);
          resolve(null)
        });
    })
  }
}
