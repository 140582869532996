import { Injectable } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import * as MapboxDirections from '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions';
import * as polyline from '@mapbox/polyline';
import * as mapboxStyle from './mapbox-style'
import { UtilsService } from './utils.service'
import axios from 'axios';
import qs from 'qs';
@Injectable({
  providedIn: 'root'
})
export class MapboxBuilderService {

  // OLD
  //MAP_ACCESS_TOKEN = `pk.eyJ1IjoidHJhdmVsc2FmZSIsImEiOiJjanJ6cGdsbG4xNnN4NDNvNXRyMGw3Y3E5In0.Ccybuu5ScOCT2JGkBJP26g`;
  //MAP_ACCESS_TOKEN = `pk.eyJ1IjoidHJhdmVsc2FmZSIsImEiOiJjazA2MzVuYWozNHRtM25xOTllbDRmeGNlIn0.QEoEy1FDfYZRSDtxzDlYVA`;
  MAP_ACCESS_TOKEN = ``;

  private axiosMapbox = null

  DriectionMethod = {
    WALKING: 'mapbox/walking',
    DRIVING: 'mapbox/driving',
    TRAFFIC: 'mapbox/driving-traffic',
    CYCLING: 'mapbox/cycling'
  }

  Style = {
    Route: {
      Passenger: mapboxStyle.stylePassengerRoute,
      BusDriver: mapboxStyle.styleBusRoute
    }
  }

  constructor(private utils: UtilsService) {
    console.log('Hello MapboxBuilderService');
  }


  _axios() {

    if (this.axiosMapbox == null) {

      this.axiosMapbox = axios.create({
        timeout: 30000
      });
    }
    this.axiosMapbox.defaults.headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/x-www-form-urlencoded'
    }
    return this.axiosMapbox
  }

  async initToken() {
    let storageToken = await this.utils.getMapBoxToken()
    this.MAP_ACCESS_TOKEN = (storageToken && storageToken != "" ? storageToken : this.MAP_ACCESS_TOKEN)
    mapboxgl.accessToken = this.MAP_ACCESS_TOKEN;
    console.log('initToken this.MAP_ACCESS_TOKEN:', this.MAP_ACCESS_TOKEN);
    console.log('initToken mapboxgl.accessToken:', mapboxgl.accessToken);
  }


  public getRouteDirection = (coordinates, profile) => new Promise(async (resolve, reject) => {

    var coordsParams = coordinates.join(';')

    this._axios().post(`https://api.mapbox.com/directions/v5/${profile}?access_token=${mapboxgl.accessToken}`, qs.stringify({
      coordinates: coordsParams,
      geometries: 'geojson'
    }))
      .then(res => {
        resolve(res.data)
      }).catch((error) => {
        console.log('getRouteDirection error', error);
        reject({
          error: true,
          message: error
        })
      });

  })

  // ex . https://api.mapbox.com/directions-matrix/v1/mapbox/driving/A;B;C;D;E?sources=0&destinations=1;2;3;4&access_token=...
  public getDirectionOneToMany = (origin, coordinates, profile) => new Promise(async (resolve, reject) => {

    let routeLngLat = origin.join() + ";" + coordinates.join(";")

    this._axios().get(`https://api.mapbox.com/directions-matrix/v1/${profile}/`
      + `${routeLngLat}`
      + `?sources=0&destinations=all`
      + `&access_token=${mapboxgl.accessToken}`
    )
      .then(res => {
        resolve(res.data)
      }).catch((error) => {
        //console.log(error.message);
        reject({
          error: true,
          message: error
        })
      });

  })


  createPassengerMarker(lnglat, id) {

    // create a HTML element for each feature
    const el = document.createElement('div');
    el.style.width = '24px'
    el.style.height = '24px'
    el.style.background = `url(assets/user-location.png) no-repeat`
    el.style.backgroundSize = 'contain'

    // make a marker for each feature and add to the map
    const marker = new mapboxgl.Marker(el).setLngLat(lnglat)

    return {
      id: id + '_marker',
      marker: marker
    };

  }

  createBusDriverMarker(map, lnglat, id, color?) {

    // create a HTML element for each feature
    const el = document.createElement('div');
    el.style.width = '40px'
    el.style.height = '40px'
    el.style.background = `url(assets/bus-icon.png) no-repeat`
    el.style.backgroundSize = 'contain'

    // make a marker for each feature and add to the map
    const marker = new mapboxgl.Marker(el).setLngLat(lnglat)
    const markerSuite = {
      id: id + '_marker',
      marker: marker,
      color: color,
      lnglat: lnglat
    };

    markerSuite.marker.addTo(map)

    return markerSuite
  }

  createTaxiDriverMarker(map, lnglat, id, color?) {

    // create a HTML element for each feature
    const el = document.createElement('div');
    el.style.width = '40px'
    el.style.height = '40px'
    el.style.background = `url(assets/taxi-green.png) no-repeat`
    el.style.backgroundSize = 'contain'

    // make a marker for each feature and add to the map
    const marker = new mapboxgl.Marker(el).setLngLat(lnglat)
    const markerSuite = {
      id: id + '_marker',
      marker: marker,
      color: color,
      lnglat: lnglat
    };

    markerSuite.marker.addTo(map)

    return markerSuite
  }


  createBusStopMarker(lng, lat, id, name?, className?) {

    // create a HTML element for each feature
    const el = document.createElement('div');
    el.style.width = '50px'
    el.style.height = '50px'
    el.style.background = `url(assets/map-location.png) no-repeat`
    el.style.backgroundSize = 'contain'
    el.className = className || ''
    // make a marker for each feature and add to the map
    const marker = new mapboxgl.Marker(el).setLngLat([lng, lat])

    return {
      id: id,
      marker: marker,
      name: name,
      lnglat: [lng, lat]
    };

  }

  createPickupBusStopMarker(lng, lat, id, name?, className?) {

    // create a HTML element for each feature
    const el = document.createElement('div');
    el.style.width = '50px'
    el.style.height = '50px'
    el.style.background = `url(assets/marker-red.png) no-repeat`
    el.style.backgroundSize = 'contain'
    el.className = className || ''
    // make a marker for each feature and add to the map
    const marker = new mapboxgl.Marker(el).setLngLat([lng, lat])

    return {
      id: id,
      marker: marker,
      name: name,
      lnglat: [lng, lat]
    };

  }

  updateMarkerPosition(map, marker, lnglat) {
    marker.setLngLat(lnglat)
    marker.addTo(map)
  }

  updatePopupPosition(map, popup, lnglat) {
    popup.setLngLat(lnglat)
    popup.addTo(map)
  }

  makeEtaPopup(map, lnglat, id, eta, pattern_name, color?) {

    let bgcolorStyle = ''
    eta = (eta && eta > 0) ? eta : 1
    eta = `${eta} Min`

    if (color) {
      bgcolorStyle = `style="color: ${color};"`
    }

    let msg = ''
    if (pattern_name) {
      msg += `<div> Next Stop:</div>`
      msg += `<div class="tracking-label" id="${id}_tracking_label_popup" ${bgcolorStyle}> ${pattern_name}</div>`
    }

    let style = `${msg}`
    style += `<ion-icon class="ion-time" name="time"/></ion-icon> ETA <div class="popupTime" id="${id}_eta_popup">${eta}</div>`

    let popup = new mapboxgl.Popup({
      closeOnClick: false,
      closeButton: false,
      offset: [0, -20],
      anchor: 'bottom'
    })

    popup.setHTML(style).setLngLat(lnglat).addTo(map)

    let popupSuite = {
      id: id + '_eta_popup',
      popup: popup,
      color: color
    }

    return popupSuite

  }

  makeStopPopup(map, lnglat, id, msg, jobId?, bgColor?) {

    console.log('makeStopPopup bgColor:', bgColor);

    if (!bgColor) {
      bgColor = "#0448a6"
    }
    const stype = `<div class="popupStop" id="${id}_stop_popup_jobId${jobId}" style="background-color: ${bgColor}"><span>&nbsp${msg}&nbsp</span></div>`
    var popup = new mapboxgl.Popup({
      className: 'custom-bus-stop-popup',
      closeOnClick: false,
      closeButton: false,
      anchor: 'bottom',
      //offset: [-9, -23]
    })

    popup.setHTML(stype).setLngLat(lnglat).addTo(map)

    const popupSuite = {
      id: id + '_stop_popup',
      popup: popup
    }

    return popupSuite

  }

  makeEtaPopupForTrackDriver(map, lnglat, id, eta, driver_name, color?) {

    color = color || '#000000'
    let bgcolorStyle = `style="background-color: ${color}; color: #ffffff"`
    let msg = driver_name
    eta = eta.toUpperCase().replace("M", "").trim()
    eta = (eta && eta > 0) ? eta : 1
    eta = `${eta} Min`

    let style = `<div class="track-your-driver-content" ${bgcolorStyle}> ${msg}`
    style += ` - ETA <div class="popupTime" id="${id}_eta_popup">${eta}</div></div>`
    style += `<div class="mapboxgl-popup-tip" style="border-top-color:${color}"></div>`
    let popup = new mapboxgl.Popup({
      className: 'track-your-driver-popup',
      closeOnClick: false,
      closeButton: false,
      offset: [0, -20],
      anchor: 'bottom'
    })

    popup.setHTML(style).setLngLat(lnglat).addTo(map)

    let popupSuite = {
      id: id + '_eta_popup',
      popup: popup,
      color: color
    }

    return popupSuite

  }

  updateEtaPopup(id, value, quote_id?, pattern_name?) {
    try {
      const nameId = id;
      var eta = document.getElementById(nameId)
      value = (value && value > 0) ? value : 1
      console.log('updateEtaPopup id:', nameId, value, eta);
      if (eta) {
        eta.innerText = `${value} Min`
      }

      var nexStopLabel = document.getElementById(`${quote_id}_tracking_label_popup`)
      console.log('nexStopLabel id:', nexStopLabel);
      if (nexStopLabel){
        nexStopLabel.innerHTML = pattern_name
      }
    } catch (error) {
      console.error('Already handle updateEtaPopup error:', error);
    }
  }

  createPassengerMaker(lng, lat) {
    // create a HTML element for each feature
    const el = document.createElement('div');
    el.style.width = '50px'
    el.style.height = '50px'
    el.style.background = `url(assets/current-location-icon.png) no-repeat`
    el.style.backgroundSize = 'contain'

    // make a marker for each feature and add to the map
    const marker = new mapboxgl.Marker(el).setLngLat([lng, lat])
    return marker;
  }

  // TODO STYLE
  createRouteDirection(method) {

    return new MapboxDirections({
      accessToken: this.MAP_ACCESS_TOKEN,
      unit: 'metric',
      profile: method,
      interactive: false,
      alternatives: true,
      styles: '',
      controls: {
        inputs: false,
        instructions: false,
        profileSwitcher: false,
      }
    });

  }

  removeMarker(db, id) {

    db.map((p, idx) => {
      if (p.id == id) {
        db[idx].marker.remove()
      }
    })

  }

  clearAllBusMarker(markersObj) {
    markersObj.map((item, idx) => {
      let id = item.id + ""
      console.log('markersObj item:', item, 'id:', id);
      if (id.search(/^.*\d(_marker)$/g) != -1) {
        markersObj[idx].marker.remove()
      }
    })
  }

  hideDefaultStyleDirection(map) {
    // remove circle origin and destination
    const directionStyleId = [
      "directions-route-line-casing",
      "directions-origin-point",
      "directions-origin-label",
      "directions-destination-point",
      "directions-destination-label",
    ];

    for (let i in directionStyleId) {
      if (typeof map.getLayer(directionStyleId[i]) != "undefined") {
        map.setLayoutProperty(directionStyleId[i], 'visibility', 'none')
      }
    }
  }


  RerenderRoute(map, route_id, style, coords, color?) {
    route_id = route_id + '_route'
    // const decCoords = polyline.decode(coords);
    console.log('add route direction', route_id)
    // If a route is already loaded, remove it
    if (map.getSource(route_id)) {
      map.removeLayer(route_id)
      map.removeSource(route_id)
    }

    const setupLayer = Object.assign({
      "id": route_id,
      "type": "line",
      "source": {
        "type": "geojson",
        "data": {
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "LineString",
            "coordinates": coords
          }
        }
      },
    }, ...style)

    map.addLayer(setupLayer);
  }

  RemoveRouteTracking(map, route_id) {
    if (map.getSource(route_id)) {
      map.removeLayer(route_id)
      map.removeSource(route_id)
    }
  }



}

