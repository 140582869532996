import { Injectable, ViewContainerRef, ComponentFactoryResolver, Type, NgZone } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { RateComponent } from '../components/rate/rate.component';
import { RateConfig } from '../interface/rate';

@Injectable()
export class RateService {

    private _viewContainerRef: ViewContainerRef

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private zone: NgZone

    ) { }

    public registerViewContainerRef(vcr: ViewContainerRef){
        this._viewContainerRef = vcr
    }

    /**
     * showRateDialog
     */
    public showRateDialog(rateConfig: RateConfig) {
        return new Observable(
            (subscriber) => this.loadDynamicComponent(
                RateComponent,
                this._viewContainerRef,
                subscriber,
                rateConfig
            )
        )
    }

    private loadDynamicComponent<T extends any>(
        component: Type<T>,
        vcr: ViewContainerRef,
        subscriber,
        config?: RateConfig
      ) {
        
        const factory: any = this.componentFactoryResolver.resolveComponentFactory(component);

        this.zone.run(() => {
          const componentRef = vcr.createComponent(factory);
          for (const key in config) {
            if (config.hasOwnProperty(key)) {
              (<T>componentRef.instance)[key] = config[key];
            }
          }
          console.log('(<T>componentRef.instance)', (<T>componentRef.instance));
          (<T>componentRef.instance)['close'] = (data: any) => {
            componentRef.destroy();
            subscriber.next(data);
          };
        })
      }

}